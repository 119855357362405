<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Calendar</div>
                    <div class="card-body">
                        <b-calendar v-model="value" locale="en-US"></b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Disabled and readonly states</div>
                    <div class="card-body">
                        <b-form-group label="Select calendar interactive state">
                            <b-form-radio-group v-model="state" aria-controls="ex-disabled-readonly">
                                <b-form-radio value="disabled">Disabled</b-form-radio>
                                <b-form-radio value="readonly">Readonly</b-form-radio>
                                <b-form-radio value="normal">Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-calendar id="ex-disabled-readonly" :disabled="disabled" :readonly="readonly"></b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Date constraints</div>
                    <div class="card-body">
                        <b-calendar v-model="value" :min="constraint.min" :max="constraint.max" locale="en"></b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Disabled dates</div>
                    <div class="card-body">
                        <b-calendar v-model="value" :date-disabled-fn="dateDisabled" locale="en"></b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Contextual Variants</div>
                    <div class="card-body">
                        <b-calendar
                            selected-variant="success"
                            today-variant="info"
                            nav-button-variant="primary"
                        ></b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Full Width</div>
                    <div class="card-body">
                        <b-calendar block locale="en-US"></b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Date Format</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <p>Custom date format:</p>
                                <b-calendar
                                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                    locale="en"
                                ></b-calendar>
                            </div>
                            <div class="col">
                                <p>Short date format:</p>
                                <b-calendar
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    locale="en"
                                ></b-calendar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Border and padding</div>
                    <div class="card-body">
                        <b-calendar class="border rounded p-2" locale="en"></b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Control Buttons</div>
                    <div class="card-body">
                        <b-calendar v-model="value" value-as-date locale="en">
                            <div class="d-flex" dir="ltr">
                                <b-button
                                    size="sm"
                                    variant="outline-danger"
                                    v-if="value"
                                    @click="clearDate"
                                >
                                    Clear date
                                </b-button>
                                <b-button
                                    size="sm"
                                    variant="outline-primary"
                                    class="ml-auto"
                                    @click="setToday"
                                >
                                    Set Today
                                </b-button>
                            </div>
                        </b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Highlight Specific Dates</div>
                    <div class="card-body">
                        <b-calendar v-model="value" :date-info-fn="dateClass" locale="en"></b-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Calendar',
        data() {
            return {
                value: null,
                state: 'disabled'
            }
        },
        computed: {
            disabled() {
                return this.state === 'disabled'
            },
            readonly() {
                return this.state === 'readonly'
            },
            constraint() {
                const now = new Date()
                const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
                // Limit to 2 weeks
                const maxDate = new Date(today)
                maxDate.setDate(maxDate.getDate() + 14);

                return {
                    min: today,
                    max: maxDate
                }
            }
        },
        methods: {
            dateDisabled(ymd, date) {
                // Disable weekends (Sunday = `0`, Saturday = `6`) and
                // disable days that fall on the 13th of the month
                const weekday = date.getDay()
                const day = date.getDate()
                // Return `true` if the date should be disabled
                return weekday === 0 || weekday === 6 || day === 13
            },
            setToday() {
                const now = new Date()
                this.value = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            },
            clearDate() {
                this.value = ''
            },
            dateClass(ymd, date) {
                const day = date.getDate()
                return day >= 10 && day <= 20 ? 'table-info' : ''
            }
        }
    }
</script>
